import React from 'react';
import PropTypes from 'prop-types';

const SignUp = ({ link }) => (
  <>
    <a className='sign-up' href={link}>
      Sign Up
    </a>
    <div className='arrow' />
  </>
);

SignUp.propTypes = {
  link: PropTypes.string
};

export default SignUp;
