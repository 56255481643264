export const BLOG_TOKENS = {
  TITLE: 'News and Articles',
  NEW: 'New',
  POPULAR: 'Popular',
  TYPES: 'Categories'
};

export const HEADER_TOKENS = [
  { title: 'What we do', path: '#what_we_do', tabIndex: 4 },
  { title: 'Tech', path: '#tech', tabIndex: 3 },
  { title: 'About Us', path: '#about', tabIndex: 2 },
  {
    title: 'Sign In',
    path: '//track.emailcheckerpro.com/click.php?key=emailcheckerpro.com',
    tabIndex: 5
  }
];
