import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const hrefWhatWeDo = '#what_we_do';
const hrefTech = '#tech';
const hrefAbout = '#about';
const techNumber = 5;
const firstNumber = 0;

const FooterMenu = ({ links }) => {
  const [articleContainer, setArticleContainer] = useState([]);
  const [mixedTextShadowContainer, setMixedTextShadowContainer] = useState([]);

  useEffect(() => {
    setArticleContainer(document.getElementsByClassName('article-container'));
    setMixedTextShadowContainer(
      document.getElementsByClassName('mixed-text-shadow-container')
    );
  }, []);

  const scrollFunction = (number, containerName) => {
    Array.prototype.forEach.call(containerName, container => {
      const offsetBody = document
        .getElementsByTagName('body')[0]
        .getBoundingClientRect().top;
      const offsetY = container.firstChild.childNodes[
        number
      ].getBoundingClientRect().top;
      window.scrollTo({
        top: offsetY - offsetBody,
        behavior: 'smooth'
      });
    });
  };

  const scrollToElement = event => {
    const {
      target: { href, title }
    } = event;
    if (title !== 'Sign In') {
      event.preventDefault();
    }
    switch (href) {
      case `${window.location.origin}/${hrefWhatWeDo}`:
        scrollFunction(firstNumber, articleContainer);
        break;
      case `${window.location.origin}/${hrefTech}`:
        scrollFunction(techNumber, articleContainer);
        break;
      case `${window.location.origin}/${hrefAbout}`:
        scrollFunction(firstNumber, mixedTextShadowContainer);
        break;
    }
  };
  return (
    <div className='footer-menu'>
      <ul>
        {links.map(({ title, path }) => {
          if (title === 'Sign In') {
            return (
              <li className='footer-menu-item' key={title}>
                <a
                  className='footer-menu-link'
                  title={title}
                  href={path}
                  onClick={e => scrollToElement(e)}
                >
                  {title}
                </a>
              </li>
            );
          } else {
            return (
              <li className='footer-menu-item' key={title}>
                <Link
                  className='footer-menu-link'
                  title={title}
                  to={path}
                  onClick={e => scrollToElement(e)}
                >
                  {title}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default FooterMenu;

FooterMenu.propTypes = {
  links: PropTypes.array,
  title: PropTypes.string,
  link: PropTypes.string
};
