import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './header.scss';

const hrefWhatWeDo = '#what_we_do';
const hrefTech = '#tech';
const hrefAbout = '#about';
const techNumber = 22;
const firstNumber = 0;

const HeaderLinks = ({ links, className, mobileOpen, isMobileOpen }) => {
  const [articleContainer, setArticleContainer] = useState([]);
  const [mixedTextShadowContainer, setMixedTextShadowContainer] = useState([]);

  useEffect(() => {
    setArticleContainer(document.getElementsByClassName('article-container'));
    setMixedTextShadowContainer(
      document.getElementsByClassName('mixed-text-shadow-container')
    );
  }, []);

  const scrollFunction = (number, containerName) => {
    Array.prototype.forEach.call(containerName, container => {
      const offsetY = container.firstChild.childNodes[
        number
      ].getBoundingClientRect().top;
      window.scrollTo({
        top: offsetY,
        behavior: 'smooth'
      });
    });
  };

  const scrollToElement = useCallback(
    event => {
      const {
        target: { href, title }
      } = event;
      if (title !== 'Sign In') {
        event.preventDefault();
      }
      switch (href) {
        case `${window.location.origin}/${hrefWhatWeDo}`:
          scrollFunction(firstNumber, articleContainer);
          break;
        case `${window.location.origin}/${hrefTech}`:
          scrollFunction(techNumber, articleContainer);
          break;
        case `${window.location.origin}/${hrefAbout}`:
          scrollFunction(firstNumber, mixedTextShadowContainer);
          break;
      }
    },
    [articleContainer, mixedTextShadowContainer]
  );

  return (
    <ul className={className}>
      {links.map(({ title, path, tabIndex }) => {
        if (title === 'Sign In') {
          return (
            <li className='header-links-list-item' key={title}>
              <a
                title={title}
                className='header-links'
                href={path}
                tabIndex={tabIndex}
                onClick={
                  isMobileOpen
                    ? e => {
                        scrollToElement(e);
                        mobileOpen();
                      }
                    : scrollToElement
                }
              >
                {title}
              </a>
            </li>
          );
        } else {
          return (
            <li className='header-links-list-item' key={title}>
              <Link
                title={title}
                className='header-links'
                to={path}
                tabIndex={tabIndex}
                onClick={
                  isMobileOpen
                    ? e => {
                        scrollToElement(e);
                        mobileOpen();
                      }
                    : scrollToElement
                }
              >
                {title}
              </Link>
            </li>
          );
        }
      })}
    </ul>
  );
};

HeaderLinks.propTypes = {
  links: PropTypes.array,
  className: PropTypes.string,
  mobileOpen: PropTypes.func,
  isMobileOpen: PropTypes.bool
};

export default HeaderLinks;
