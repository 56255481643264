import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({ text, link, className }) => (
  <a className={className} href={link}>
    {text}
  </a>
);

Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string
};

export default Button;
