import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import LinkImage from '../Common/LinkImage';
import { HEADER_TOKENS as HEADER_LINKS } from '../../constants/Languages';
import FooterMenu from './FooterMenu';
import ArrowIcon from './ArrowIcon';

import './Footer.scss';

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "PRO.svg" }) {
            publicURL
          }
          pages: allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/" } }
          ) {
            totalCount
            edges {
              node {
                frontmatter {
                  title
                  path
                  tags
                }
              }
            }
          }
        }
      `}
      render={data => {
        const {
          pages: { edges }
        } = data;
        let secondHeaderLinks = [];
        edges.forEach(
          ({
            node: {
              frontmatter: { title, path, tags }
            }
          }) => {
            if ((tags || []).includes('secondary_header')) {
              secondHeaderLinks.push({ title, path });
            }
          }
        );
        return (
          <footer className='footer-container'>
            <div className='footer'>
              <LinkImage
                src={data.logo.publicURL}
                href='/'
                alt='Chech eMails'
                imgClassName='footer-logo'
                linkClassName='footer-logo-container'
              />
              <FooterMenu links={HEADER_LINKS} />
              <span className='copyrights'>Copyrights &copy; 2019-2020</span>
              <ArrowIcon />
            </div>
          </footer>
        );
      }}
    />
  );
};

export default Footer;
